<template>
	<div>
		<pui-intro-layout />
	</div>
</template>

<script>
export default {
	mounted() {
		this.$puiRequests.getRequest(
					'/userapp/getDistributionAppData',
					null,
					(response) => {
					this.$store.state.global.appDistribution= response.data.distribution;
					/*if(response.data === 'BALEARES') {
						const logoElement = document.getElementsByClassName('login__logo')[0];
						if (logoElement && !logoElement.className.includes('BALEARES')) {
							logoElement.setAttribute('class', 'login__logo login__logo--BALEARES');
						}
					}*/
						var logoElement = document.getElementsByClassName('login__logo')[0];
						if (logoElement && response.data.logo) {
							logoElement.setAttribute('style', "background:url('data:image/png;base64, "+response.data.logo+"') no-repeat !important; background-size: cover");
						}
						const imgElement = document.getElementsByClassName('pui-login__display-area')[0];
						if (imgElement && response.data.image) {
							imgElement.setAttribute('style', "background:url('data:image/png;base64, "+response.data.image+"') !important");

						}
					},
					(e) => {
						console.log(e);
					}
				);

			const loginTitle = document.getElementsByClassName('pui-login__title')[0];

			if (loginTitle) {
				var span = document.createElement('div');
				span.innerHTML = '*';
				span.className = 'prevlogin_title';
				loginTitle.parentNode.insertBefore(span, loginTitle);
			}
	}
}
</script>
